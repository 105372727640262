<template>
  <div>
    <div class="flex-center">
      <v-btn
        class="icon-action bacg-color mr-3"
        small
        color="#5C2DD3"
        outlined
        :title="$t('add', { msg: '' })"
        @click.prevent.stop="handleAddClick"
      >
        <v-icon class="img-h-30"> mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="field && (field.value || field.lastValue)"
        class="icon-action bacg-color"
        small
        color="#5C2DD3"
        outlined
        :title="$t('update')"
        @click.prevent.stop="handleUpdateClick"
      >
        <v-icon class="img-h-30"> mdi-square-edit-outline</v-icon>
      </v-btn>
    </div>

    <!-- Add Resoource Modal -->
    <v-dialog v-model="modalAddRessource" max-width="900" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Ajouter une ressource' : 'Add resource'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalAddRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="modalAddRessource" class="mt-2">
            <v-row class="row">
              <v-col>
                <!-- NOM RESOURCE -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="resourceToAdd.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- DESCRIPTION -->
                <v-textarea
                  dense
                  label="Description"
                  v-model="resourceToAdd.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
                <!-- ACTIVE / DESACTIVE RESOURCE -->
                <v-switch
                  :label="
                    !resourceToAdd.active
                      ? $i18n.locale == 'fr'
                        ? 'Désactivé'
                        : 'Disabled'
                      : $i18n.locale == 'fr'
                      ? 'Active'
                      : 'Active'
                  "
                  color="#5C2DD3"
                  v-model="resourceToAdd.active"
                  :class="{ 'color-bold': resourceToAdd.active }"
                  class="bold-700 ml-2 margin-switch-input"
                  :true-value="1"
                  :false-value="0"
                >
                </v-switch>
                <!-- TYPE RESOURCE -->
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  @input="changeFieldsTypeResource"
                  :loading="getTypeResourceLoading"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="resourceToAdd.type"
                  :items="getTypeResource"
                  :persistent-placeholder="true"
                  :label="
                    $i18n.locale === 'fr' ? 'Type ressources' : 'Resource type'
                  "
                  item-text="designation"
                  dense
                  return-object
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'type ressources'
                        : 'resource type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- CHAMP DYNAMIQUE  -->
            <div
              v-if="
                resourceToAdd.type &&
                  resourceToAdd.type.fields &&
                  resourceToAdd.type.fields.length
              "
            >
              <v-row
                v-for="field in resourceToAdd.type.fields"
                :key="'field-add-' + field.id + `${resourceToAdd.type.id}`"
              >
                <v-col>
                  <div class="value">
                    <EditableCustomFieldForm
                      :readonly="false"
                      :field="field"
                      :value="field.value"
                      :typeRessource="resourceToAdd.type.fields"
                      @updateFunction="updateFunction"
                      :fieldKeyEdit="false"
                      :required="field.required"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderAddRessource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalAddRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Resoource Modal -->
    <!-- UPDATE Resoource Modal -->
    <v-dialog
      v-model="modalUpdateRessource"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Modifier une ressource' : 'Edit resource'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalUpdateRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="modalUpdateRessource" class="mt-2">
            <v-row class="row">
              <v-col>
                <!-- NOM RESOURCE -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="resourceToUpdate.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- DESCRIPTION -->
                <v-textarea
                  dense
                  label="Description"
                  v-model="resourceToUpdate.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
                <!-- ACTIVE / DESACTIVE RESOURCE -->
                <v-switch
                  :label="
                    !resourceToUpdate.active
                      ? $i18n.locale == 'fr'
                        ? 'Désactivé'
                        : 'Disabled'
                      : $i18n.locale == 'fr'
                      ? 'Active'
                      : 'Active'
                  "
                  color="#5C2DD3"
                  v-model="resourceToUpdate.active"
                  :class="{ 'color-bold': resourceToUpdate.active }"
                  class="bold-700 ml-2 margin-switch-input"
                  :true-value="1"
                  :false-value="0"
                >
                </v-switch>
                <!-- TYPE RESOURCE -->
                <v-autocomplete
                  color="#5C2DD3"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="resourceToUpdate.type"
                  :items="getTypeResource"
                  :persistent-placeholder="true"
                  disabled
                  :label="
                    $i18n.locale === 'fr' ? 'Type ressources' : 'Resource type'
                  "
                  item-text="designation"
                  :loading="getTypeResourceLoading"
                  dense
                  return-object
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- CHAMP DYNAMIQUE  -->
            <div
              v-if="
                resourceToUpdate &&
                  resourceToUpdate.type &&
                  resourceToUpdate.fields &&
                  resourceToUpdate.fields.length
              "
            >
              <v-row
                v-for="field in resourceToUpdate.fields"
                :key="
                  'field-update-' + field.id + `${resourceToUpdate.type.id}`
                "
              >
                <v-col>
                  <div class="value">
                    <EditableCustomFieldForm
                      :readonly="false"
                      :field="field"
                      :value="field.value"
                      :typeRessource="resourceToUpdate.fields"
                      :fieldKeyEdit="false"
                      @updateFunction="updateFunctionUpdateResource"
                      :required="field.required"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderUpdateRessource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalUpdateRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END UPDATE Resoource Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    EditableCustomFieldForm: () =>
      import('@/components/CrmIla26Component/EditableCustomFieldForm')
  },
  props: {
    field: { required: true },
    project: { required: true },
    event: { required: false }
  },
  data() {
    return {
      resourceToUpdate: {
        designation: null,
        detail: null,
        type: null,
        fields: [],
        type_projet: null,
        active: 1
      },
      resourceToAdd: {
        designation: null,
        detail: null,
        type: null,
        fields: [],
        type_projet: null,
        active: 1
      },
      resourceToUpdateOriginal: null,
      loading: false,
      modalUpdateRessource: false,
      modalAddRessource: false,
      errorMessages: null
    }
  },
  methods: {
    ...mapActions([
      'updateResource',
      'resetErrorResource',
      'fetchOneTypeResource',
      'fetchOneResource',
      'fetchAllTypeResource',
      'resetErrorTypeResource',
      'addResource',
      'fetchAllTypeEvent',
      'fetchOneTypeEvent'
    ]),
    async changeFieldsTypeResource(field) {
      if (field) {
        await this.fetchOneTypeResource(field.id)
      }
    },
    handleAddClick() {
      this.resetModalAdd()
      this.modalAddRessource = true
      this.$emit('openModalResource')
    },
    updateFunction(field) {
      this.resourceToAdd.fields = field
    },
    updateFunctionUpdateResource(field) {
      this.resourceToUpdate.fieldsUpdate = field
    },
    async handleUpdateClick() {
      this.$emit('openModalResource')

      await this.fetchOneResource(
        this.field.lastValue ? this.field.lastValue : this.field.value
      ).then(() => {
        this.modalUpdateRessource = true
        let copieObjet = { ...this.getOneResource }
        this.resourceToUpdate.designation = copieObjet.designation
        this.resourceToUpdate.detail = copieObjet.detail
        this.resourceToUpdate.type = copieObjet.type
        this.resourceToUpdate.fields = copieObjet.fields
        this.resourceToUpdateOriginal = this.getOneResource
      })
    },
    async closeDialog(ref) {
      this[ref] = false
      if (ref == 'modalAddRessource') {
        this.$refs.modalAddRessource.resetValidation()
        this.resetErrorResource()
        this.resetErrorTypeResource()
      }

      if (ref == 'modalUpdateRessource') {
        this.$refs.modalUpdateRessource.resetValidation()
        this.resetErrorResource()
        this.resetFieldsModal()
      }
      if (this.event && this.event.type && this.event.type.id) {
        await this.fetchOneTypeEvent(this.event.type.id)
      }
      this.$emit('closeModalResource')
    },
    async handleValiderAddRessource() {
      if (this.$refs.modalAddRessource.validate()) {
        this.loading = true
        const response = await this.addResource(this.resourceToAdd)
        if (response) {
          this.closeDialog('modalAddRessource')
        }
        this.loading = false
      }
    },
    async handleValiderUpdateRessource() {
      if (this.$refs.modalUpdateRessource.validate()) {
        this.loading = true
        this.resourceToUpdate.id = this.resourceToUpdateOriginal.id
        const response = await this.updateResource({
          body: this.resourceToUpdate,
          resource: this.resourceToUpdateOriginal
        })
        if (response) {
          this.closeDialog('modalUpdateRessource')
        }
        this.loading = false
      }
    },
    resetModalAdd() {
      this.resourceToAdd = {
        designation: null,
        detail: null,
        type: null,
        fields: [],
        active: 1
      }
    },
    resetFieldsModal() {
      this.resourceToUpdate = {
        designation: null,
        detail: null,
        type: null,
        fields: [],
        type_projet: null,
        active: 1
      }
      this.resourceToUpdateOriginal = null
    }
  },
  computed: {
    ...mapGetters([
      'getResource',
      'getResourceLoading',
      'getResourceError',
      'getTypeResource',
      'getOneResource',
      'getOneTypeResource',
      'getTypeResourceLoading',
      'getTypeResourceError',
      'countPaginationResource'
    ]),
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getTypeResourceError(newValue) {
      this.errorMessages = newValue
    },
    getResourceError(newValue) {
      this.errorMessages = newValue
    }
  },
  mounted() {
    if (this.project && this.project.type_id) {
      this.fetchAllTypeResource({ type_projet_id: this.project.type_id })
    }
  }
}
</script>
<style lang="scss" scoped>
.bacg-color {
  background-color: rgb(216 205 245);
}
</style>
